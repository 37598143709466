import Axios from 'axios'

let url 
if (!window.location.href.includes('localhost')) {
    url = process.env.REACT_APP_API_URL
} else {
    url = 'http://localhost:8080/api'
}

const axiosStudent = Axios.create({
    baseURL: url
})

export default axiosStudent